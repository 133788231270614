import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
//const language = LanguageDetector.detect();


i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //lng: language,
    fallbackLng: "en",
    debug: true,
    initImmediate: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      //order: ['querystring', 'navigator'],//is an array that determines the order in which the language detector should check for the user's language.
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupQuerystring: 'lng',//is a string that determines the name of the querystring parameter to check for the user's language.
      caches: ['localStorage', 'cookie'],//is an array that determines which caches the language detector should use to store the detected language.
    }
  });

export default i18n;
