import React, { useEffect, useMemo } from 'react'
import i18n from '../i18n'
import { BrowserRouter as Router } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from './auth-context'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SelectionContextProvider } from './selection-context'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { LanguageContext, localeMap } from './language-context'
import { blueGrey, blue, red, green } from '@mui/material/colors'
import LocaleCode from 'locale-code' //for country-region code tranformation to country code

const queryClient = new QueryClient()

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[700],
    },
    //secondary: {
    // This is green.A700 as hex.
    //main: '#11cb5f',
    //},
    tableRow: {
      main: blueGrey[100],
      hover: blue[50],
    },
    text: {
      alarm: red[800],
      ok: green[800]
    }
  },
})

const langList = {
  en: true,
  lv: true,
  de: true,
  es: true,
  fi: true,
  no: true,
};

function initLanguage() {
  let countryCodeOnly = i18n.language ? LocaleCode.getLanguageCode(i18n.language) : null;
  if (countryCodeOnly) {
    if (langList[countryCodeOnly]) return countryCodeOnly;
    else return "en";
  }
  if (langList[i18n.language]) {
    return i18n.language;
  } else {
    if (i18n.language !== "en") i18n.changeLanguage("en");
    return "en";
  }
}

function AppProviders({ children }) {
  const [locale, setLocale] = React.useState(initLanguage())
  const language = useMemo(() => ({ locale, setLocale }), [locale, setLocale])

  useEffect(() => {
    i18n.on("languageChanged", (lng) => {
      if (!lng) return;
      let lngCodeOnly = LocaleCode.getLanguageCode(lng);
      let rez = lngCodeOnly ? lngCodeOnly : lng;
      if (!langList[rez]) rez = "en";
      //console.log(`Detected ${lng} transformed ${lngCodeOnly} old:${i18n.language} rez:${rez}`)
      //setLocale(LocaleCode.getLanguageCode(lng));
      setLocale(rez);
      if (rez !== lng) i18n.changeLanguage(rez);
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <LanguageContext.Provider value={language}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]}>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <SelectionContextProvider>{children}</SelectionContextProvider>
              </ThemeProvider>
            </AuthProvider>
          </LocalizationProvider>
        </LanguageContext.Provider>
      </Router>
    </QueryClientProvider>
  )
}

export { AppProviders }
