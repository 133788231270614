import i18next from 'i18next';
const defaultWaitTimeLimit = 300 //seconds

function date_to_string(date,add_seconds){
  let seconds
  let local_date = new Date(date)
  if (add_seconds) seconds ={second: "2-digit"}
  return local_date.toLocaleString("lv-LV", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    ...seconds,
  });
}

function dayOfDateToString(date){
  return date.toLocaleString("lv-LV", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
}

function isValidDate(date){
  return date !== null && !isNaN(date) && date?.getFullYear() > 1900 && date?.getFullYear() < 2100
}

function isValidNonZeroDate(date){
  return date !== null && !isNaN(date) && date?.getFullYear() > 1900 && date?.getFullYear() < 2100
  && date - new Date() > 0
}

const isValidDateInterval =(interval)=>isValidDate(interval?.start) && isValidDate(interval?.end) && interval.start<interval.end

const intervalHasChanged = (old_interval, new_interval) =>{
  if(!isValidDateInterval(new_interval)) return false
  if(!isValidDateInterval(old_interval))
    if(isValidDateInterval(new_interval)) return true; else return false;
  return (
    old_interval.start !== new_interval.start ||
    old_interval.end !== new_interval.end ||
    old_interval.waitTimeLimit !== new_interval.waitTimeLimit
  )
}
  

function getDateString(date) {
  if(date){
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  return null;
}

function periodByType(type){

  function getMonday(d) {
    let ms = d - 0
    while((new Date(ms)).getDay() !== 1){
      ms -= 60*60*24*1000
    }
    return new Date(new Date(ms).setHours(0, 0, 0, 0));
  }

  function getSunday(d) {
    let ms = d - 0
    while((new Date(ms)).getDay() !== 0){
      ms += 60*60*24*1000
    }
    return new Date(new Date(ms).setHours(23, 59, 59, 999));
  }

  switch (type){
    case "today":
      return ({start:new Date(new Date().setHours(0,0,0,0)),end:new Date(new Date().setHours(23,59,59,999))})
    case "last24h":
      return getLast24hInterval()
    case "yesterday":
      return ({
        start: new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)),
        end: new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(23, 59, 59, 999)),
      })
    case "this_week":
      return ({start: getMonday(new Date()),end: getSunday(new Date())})
    case "this_month":
      return ({
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 999)),
      })
    case "previous_week":
      return ({
        start: getMonday(new Date(Date.now() - 604800000)),
        end: getSunday(new Date(Date.now() - 604800000)),
      })
    case "previous_month":
      return ({
        start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        end: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 0).setHours(23, 59, 59, 999)),
      })
    default:
      return {start:'',end:''}
  }
}

function timestampToLocalString(timestamp, hideSeconds) {
  let format = hideSeconds
  ? { hour12: false, month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', }
  : { hour12: false, month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', }
  
  return (new Date(timestamp)).toLocaleString('lv-LV', format)
}

function timestampToTimeOnlyString(timestamp, hideSeconds) {
  let format = hideSeconds
  ? { hour: '2-digit', minute: '2-digit' }
  : { hour: '2-digit', minute: '2-digit', second: '2-digit' }
  
  return (new Date(timestamp)).toLocaleString('lv-LV', format)
}

function timeIntervalToString(start, end, hideSeconds) {
  let intervalEnd = isValidDate(end) ? ' - ' + (areDatesTheSameDay(start, end) ? timestampToTimeOnlyString(end, hideSeconds) : timestampToLocalString(end, hideSeconds)) : ''
  return `${timestampToLocalString(start, hideSeconds)}${intervalEnd}`
}

function intervalToString(seconds, showSeconds) {
  let days = Math.floor(seconds / 86400)
  let hours = Math.floor(seconds % 86400 / 3600)
  let minutes = Math.floor(seconds % 86400 % 3600 / 60)
  let sec = Math.floor(seconds % 86400 % 3600 % 60)
  let result = ''
  if(showSeconds){
    result = `${sec} s`
    if(minutes > 0) {
      result = `${minutes} min ` + result
    }
  }
  else{
    result = `${(seconds > 0 && seconds < 60) ? '<1' : minutes} min`
  }
  
  if(hours > 0){
    result = `${hours} h ` + result
  }
  if(days > 0){
    result = `${days} d ` + result
  }
  return result
}

function intervalToTranslatedString(seconds, showSeconds, translateKey) {
  let days = Math.floor(seconds / 86400)
  let hours = Math.floor(seconds % 86400 / 3600)
  let minutes = Math.floor(seconds % 86400 % 3600 / 60)
  let sec = Math.floor(seconds % 86400 % 3600 % 60)
  let res={}
  let context = 'withoutSeconds'
  if(showSeconds){
    res={
      context:'withSeconds',
      seconds:i18next.t('dateTime.time.agoShort.second',{count:sec})
    }
    if(minutes > 0) res.minutes=minutes? i18next.t('dateTime.time.agoShort.minute',{count:minutes}) : ''
  }
  else{
    if(seconds > 0 && seconds < 60) context='lessThanMinute'
    else res.minutes=minutes? i18next.t('dateTime.time.agoShort.minute',{count:minutes}) : ''
  }
  if(context!=='lessThanMinute'){
    res.hours=hours? i18next.t('dateTime.time.agoShort.hour',{count:hours}) : ''
    res.days=days ? i18next.t('dateTime.time.agoShort.day',{count:days}) : ''
  }
  return i18next.t(`dateTime.time.${translateKey}.${context}`,res)
}

function intervalToStringSteps(seconds, translateKey) {
  //if(seconds<60) return `${Math.floor(seconds)}sec` + ending
  if(seconds<23) return i18next.t('dateTime.time.intervalSteps.sec15') //0-22
  if(seconds<38) return i18next.t('dateTime.time.intervalSteps.sec30') //23-37
  if(seconds<53) return i18next.t('dateTime.time.intervalSteps.sec45') //38-52
  if(seconds<60) return i18next.t('dateTime.time.intervalSteps.min1') //53-59
  // if(seconds<60*5) return '5min' + ending
  // if(seconds<60*10) return '10min' + ending
  // if(seconds<60*30) return '30min' + ending
  // if(seconds<60*60) return '1h' + ending
  return intervalToTranslatedString(seconds,false,translateKey ?? 'timePeriodAgo') 
}

function getCurrentDayStart(){
  let start = new Date()
  start.setHours(0, 0, 0, 0)
  return start
}

function getCurrentDayInterval(){
  let start = new Date()
  start.setHours(0, 0, 0, 0)
  let end = new Date()
  end.setHours(23, 59, 59, 999)
  return {start, end }
}

function getLast24hInterval(){
  let end = new Date()
  let start = new Date(end - 24*60*60*1000)
  return {start, end }
}

function areDatesTheSameDay(date1, date2){
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() &&date1.getDate() === date2.getDate()
}

function getDateTime(date, time) {
  let dateTime = new Date(date)
  dateTime.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), 0)
  return dateTime
}

function splitPeriod(period) {
  let start = splitDateTime(period.start)
  let end = splitDateTime(period.end)
  return {startDate: start.date, startTime: start.time, endDate: end.date, endTime: end.time}
}

function splitDateTime(dateTime) {
  let result = {date: new Date(), time: new Date()}
  
  var year = dateTime.getFullYear()
  var month = dateTime.getMonth()
  var day = dateTime.getDate()
  result.date.setFullYear(year, month, day)
  
  let seconds = dateTime.getSeconds()
  let minutes = dateTime.getMinutes()
  let hours = dateTime.getHours()
  result.time.setHours(hours, minutes, seconds, 0)

  return result
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export {date_to_string, dayOfDateToString, isValidDate, isValidDateInterval, periodByType, getDateString, timestampToLocalString, intervalHasChanged,
  intervalToString, timestampToTimeOnlyString, getCurrentDayInterval, areDatesTheSameDay, timeIntervalToString, getLast24hInterval,
  getDateTime, splitPeriod, splitDateTime, monthNames, isValidNonZeroDate, defaultWaitTimeLimit, intervalToStringSteps, getCurrentDayStart, intervalToTranslatedString}